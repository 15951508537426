import VCard from "vcard-creator";
import { saveAs } from "file-saver";

const createVcj = (person) => {
  console.log(person);
  const vCard = new VCard();

  vCard.addName(person.lastName, person.firstName);

  if (person.companyName) {
    vCard.addCompany(person.companyName);
  }

  if (person.email) {
    vCard.addEmail(person.email, "PREF;WORK");
  }

  // if (person.pec) {
  //   vCard.addEmail(person.pec, "PREF;WORK");
  // }

  if (person.mobile) {
    person.mobile = person.mobilePrefix + person.mobile;
    vCard.addPhoneNumber(person.mobile, "PREF;WORK");
  }

  if (person.phone) {
    person.phone = person.phonePrefix + person.phone;
    vCard.addPhoneNumber(person.phone);
  }

  if (person.website) {
    vCard.addURL(person.website);
  }

  (function () {
    const blob = new Blob([vCard], { type: "text/vcard;charset=utf-8" });
    saveAs(blob, `${person.firstName}${person.lastName}.vcf`);
  })();
};
export default createVcj;
